<template>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
        <md-content>
            <div class="dialog-header">
                <div class="dialog-title">{{ title }}</div>
                <div class="dialog-close" @click="showDialog = false">
                    Close
                </div>
            </div>
            <div class="dialog-content">
                <div class="row">
                    <div class="col l-12 m-12 c-12">
                        <md-field :class="{'md-invalid': submitted && $v.entity.code.$error }">
                            <label for="code">Mã đơn vị tính</label>
                            <md-input name="code" v-model="entity.code"></md-input>
                            <span class="md-error" v-if="submitted && !$v.entity.code.required">Vui lòng nhập mã đơn vị tính</span>
                        </md-field>

                        <md-field :class="{'md-invalid': submitted && $v.entity.unitName.$error }">
                            <label for="unitName">Tên đơn vị tính</label>
                            <md-input name="unitName" v-model="entity.unitName"></md-input>
                            <span class="md-error" v-if="submitted && !$v.entity.unitName.required">Vui lòng nhập tên đơn vị tính</span>
                        </md-field>

                        <div class="form-control">
                            <md-radio v-model="entity.isActive" :value="true">Hoạt động</md-radio>
                            <md-radio v-model="entity.isActive" :value="false">Khóa</md-radio>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dialog-actions">
                <md-checkbox v-if="id == 0" v-model="saveAndCreate" class="md-primary">Lưu và tạo mới</md-checkbox>
                <md-button v-shortkey="['ctrl', 'l']" @shortkey="submit()" @click="submit()" class="md-raised md-primary"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                <md-button v-shortkey="['ctrl', 'đ']" @shortkey="showDialog = false" @click="showDialog = false" class="md-raised"><span>Đ</span>óng<md-tooltip>Đóng (Ctrl + Đ)</md-tooltip></md-button>
            </div>
        </md-content>
    </md-dialog>
</template>

<script>
    import unitService from '../../../api/unitService';
    import messageBox from '../../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';

    export default ({
        metaInfo: {
            title: 'Thêm/Cập nhật danh mục đơn vị tính'
        },
        data() {
            return {
               title: '',
               id: 0,
               submitted: false,
               showDialog: false,
               saveAndCreate: false,
               entity: { id: 0, code: '', unitName: '', orderType: 2, isActive: true },
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            async open(id){
                this.id = id;
                if(id == 0){
                    this.title = 'Thêm mới đơn vị tính';
                    this.entity = { id: 0, code: '', unitName: '', orderType: 2, isActive: true };
                }
                else {
                    this.saveAndCreate = false;
                    this.title = 'Cập nhật đơn vị tính';
                    this.getById();
                }
                this.submitted = false;
                this.showDialog = true;
            },

            close(){
                this.showDialog = false;
            },

            submit(){
               this.submitted = true;
               this.$v.$touch();
               if (this.$v.$invalid) {
                   return;
               }
               if(this.id > 0){
                   this.edit();
               }
               else{
                   this.add();
               }
            },

            add(){
                this.setLoading(true);
                this.entity.orderType = 2;
                unitService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                        this.$emit('close');
                        if(!this.saveAndCreate){
                            this.showDialog = false;
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                unitService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.$emit('close');
                        this.showDialog = false;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                unitService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            }
        },
        validations: {
            entity: {
                code: { required },
                unitName: { required },
            }
        },
    })

</script>

<style lang="css" scoped>
    .dialog-title {
        font-size: 16px;
    }
    .dialog-header {
        border-bottom: 1px solid #ccc;
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0 15px;
    }
    .md-content {
        width: 600px;
        height: 310px;
        max-width: 600px;
    }
    .md-scrollbar {
        height: 310px;
        padding-bottom: 20px;
    }
    .dialog-content {
        padding: 10px 15px;
    }
    .dialog-actions {
        height: 35px;
        border-top: 1px solid #ccc;
        padding-top: 15px;
        display: flex;
        align-items: center;
        justify-content: end;
    }
</style>
